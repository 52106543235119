import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  __name: 'modal-newlyMenuRule',

  setup(__props, {
    expose: __expose
  }) {
    const titletext = ref();
    const centerDialogVisible = ref(false);

    const showModal = data => {
      titletext.value = data.titletext;
      centerDialogVisible.value = true;
    };

    __expose({
      showModal
    });

    const formRef = ref();
    const numberValidateForm = ref({
      rule: '',
      type: '',
      title: '',
      name: '',
      path: ''
    });
    const rules = {
      rule: [{
        required: true,
        message: '请填写规则',
        trigger: 'blur'
      }],
      type: [{
        required: true,
        message: '请填写规则类型',
        trigger: 'blur'
      }],
      title: [{
        required: true,
        message: '请填写规则标题',
        trigger: 'blur'
      }],
      name: [{
        required: true,
        message: '请填写路由名称',
        trigger: 'blur'
      }],
      path: [{
        required: true,
        message: '请填写路由路径',
        trigger: 'blur'
      }]
    }; // 提交

    const submitForm = formEl => {
      if (!formEl) return;
      formEl.validate(valid => {
        if (valid) {
          console.log('提交');
          centerDialogVisible.value = false;
          ElMessage({
            message: `${titletext.value}成功`,
            type: 'success'
          });
          formEl.resetFields();
        } else {
          console.log('填写有误!');
        }
      });
    }; // 重置


    const resetForm = formEl => {
      console.log('重置');
      if (!formEl) return;
      formEl.resetFields();
    }; // 关闭


    const close = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: centerDialogVisible.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => centerDialogVisible.value = $event),
        title: titletext.value,
        "align-center": "",
        onClose: _cache[8] || (_cache[8] = $event => close(formRef.value))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          rules: rules,
          model: numberValidateForm.value,
          "label-width": "auto",
          class: "demo-ruleForm"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "规则",
            prop: "rule"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.value.rule,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => numberValidateForm.value.rule = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "规则类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.value.type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => numberValidateForm.value.type = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "规则标题",
            prop: "title"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.value.title,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => numberValidateForm.value.title = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "路由名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.value.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => numberValidateForm.value.name = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "路由路径",
            prop: "path"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: numberValidateForm.value.path,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => numberValidateForm.value.path = $event),
              modelModifiers: {
                number: true
              },
              type: "text",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[5] || (_cache[5] = $event => submitForm(formRef.value))
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }), _createVNode(_component_el_button, {
              onClick: _cache[6] || (_cache[6] = $event => resetForm(formRef.value))
            }, {
              default: _withCtx(() => [_createTextVNode("重置")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};